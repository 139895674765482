$colour-blue: #87CEEB; 
$colour-dark-blue: #3DAEDC; 
$colour-yellow: #FFEE93;
$colour-brown: #DE8F6E;
$colour-dark-brown: #461220;
$colour-purple: #C09BD8; 
$colour-dark-purple: #9F84BD;
$colour-orange: #F9AB55;
$colour-dark-orange: #F58A07;;
$colour-sand: #FCF5C7; 
$colour-dark-green: #8CAE80;
$colour-green: #A1C893;
$colour-light-green: #A8D394;
$colour-sky: #E8FFFE;
$dot-colour: #C4C4C4;

$font-family: 'Montserrat';
$font-size-card-title: 36px;
$font-size-nav: 24px;
$font-size-progress: 20px;
$font-size-h1: 60px;
$font-size-h2: 40px;
$font-size-h3: 30px;
$font-size-h4: 24px;
$font-size-p: 16px;

$margin-single: 4px;
$padding-single: 4px;

$box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2), 0 4px 7px rgba(0, 0, 0, 0.27);
$border-radius: 30px;

$nav-height: 60px;

$mobile-break-point: 426px;
$tablet-break-point: 769px;

%card {
    width: 320px;
    height: 400px;
    border-radius: 30px;
    box-shadow: $box-shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 $margin-single*2;


    %innerCard {
      height: 70%;
      width: 90%;
      overflow-y: auto;

  %innerExpandedCard {
    max-height: 497px;
    max-width: 818px;
    border-radius: 30px;
  }

  %button {
    color: black;
    background-color: $colour-blue;
    border-radius: $border-radius;
    width: 30%;
    border: none;
    padding: 20px;
    margin: 20px;
    font-size: $font-size-h3;
    
    &:hover {
        background-color: $colour-dark-blue;  
    }

    *:focus {
        outline: none;
    }
}

    *:focus {
        outline: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  
    %expandedCard {
      max-height: 779px;
      max-width: 1177px;
      border-radius: 30px;
      box-shadow: $box-shadow;
    }
  
    %innerExpandedCard {
      max-height: 497px;
      max-width: 818px;
      border-radius: 30px;
    }
    }
}