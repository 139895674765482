@import '../../../variables.scss';

.backgroundDiv {
    margin: 0;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 2em;
    }

    a{
        font-weight: bolder;
        color: white;

        &:hover{
            color: $colour-dark-orange;
        }
    }
    
    .dashboardView {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        
        .updateSection {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        
        .progressSection {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
        }
    }
    
}


@media only screen and  (min-width: $tablet-break-point) {

    .backgroundDiv {
        justify-content: center;
        height: calc(100vh - #{$nav-height});

        h1 {
            font-size: 3em;
        }
        
        .dashboardView {
            height: 80%;
            width: 80%;
            flex-direction: row;
            justify-content: space-between;
            
            .updateSection {
                height: 80%;
                width: 80%;
                justify-content: space-between;
            }
            
            .progressSection {
                height: 80%;
                width: 80%;
            }
        }
        
    }
}
