@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap');
@import "../../variables.scss";

.polaroid{
  border: 2px solid black;
  height: 280px;
  width: 220px;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 20px;
  box-shadow: $box-shadow;

  @media screen and (max-width: 425px) {
    height: 380px;
    width: 320px;
  }
  @media screen and (max-width: 350px) {
    height: 330px;
    width: 320px;
  }


  .avatar{
    height: 200px;
    width: 200px;
    position: flex;
    justify-content: center;
    margin-top: 10px;
    border: 2px solid black;

    img{
      height: 200px; 
      border: 1px solid black;
    }
    @media screen and (max-width: 425px) {
      height: 300px;
      width: 260px;
    }
    @media screen and (max-width: 350px) {
      height: 230px;
      width: 230px;
    }
  }

  .caption{
    font-family: 'Just Another Hand', cursive;
    position: absolute;
    bottom: 20px;
    font-size: 1.5em;
    color: $colour-dark-brown;
    text-align: center;
    margin-left: 20px;

    .icon {
      margin-left: 20px;
      color: grey;
      opacity: 0.5;
    }
  }

  .delete{
    &:hover{
      color: rgba($color: #ff0000, $alpha: 2.0);
      cursor: pointer;
    }
  }
}