.cardcarousel {
    width: 98%;

    .CardCarousel {
        padding: 0px;
    }
    
    // .sectionFlex {
    //     display: flex;
    //     justify-content: space-between;
    // }

    *:focus {
        outline: none;
    }
}