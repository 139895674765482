@import "../../variables.scss";

    .container {
      position: relative;
      max-width: 100%;
      width: 90%;
      height: 50px;
      background-color: $colour-dark-blue;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @media only screen and (max-width: $mobile-break-point){
        height: 30px;
      }


      .complete {
        position: absolute;
        left: 0;
        top: 0px;
        height: 100%;
        background-color: white;
        border-radius: 10px;
        animation: g 2500ms infinite ease-in-out;
        z-index: 2;

        .liquid {
          z-index: 1;
          width: 70px;
          height: 70px;
          animation: g 4500ms infinite ease-in-out, r 4000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          position: absolute;
          right: -5px;
          top: -10px;
          background-color: white;
          border-radius: 40%;

          @media only screen and (max-width: $mobile-break-point){
            width: 60px;
            height: 60px;
          }
        }
      }

      .progress {
        z-index: 2;
        font-weight: bold;
      }
    }
  
  @keyframes g {
    0% { background-color: white; }
    100% { background-color: white; }
  }
  
  @keyframes r {
    from { transform: rotate(0deg); }
    from { transform: rotate(360deg); }
  }

  