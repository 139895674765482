@import '../../variables.scss';


.buttonSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.galleryContainer {
  height: 100%;
}

.galleryTitle {
  background-color: $colour-orange;

}

.gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .polaroidContainer {
    height: 60vh;
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .buttonWrapper {
    width: 100%;
    
  }
}

.customfileupload {
  color: black;
  background-color: $colour-blue;
  border-radius: $border-radius;
  width: 250px;
  border: none;
  padding: 18px;
  padding-left: 65px;
  padding-right: 65px;
  margin: 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: $box-shadow;
  white-space: nowrap;
  text-align: center;

  @media only screen and (max-width: 400px) {
      width: 180px;
      padding-left: 45px;
      padding-right: 45px;
  }

  input[type="file"] {
    display: none;
  }

  &:hover {
    background-color: $colour-dark-blue;
  }

  &.active {
    background-color: green;
  }

  *:focus {
    outline: none;
  }
}

*:focus {
  outline: none;
}