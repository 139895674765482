@import "../../../variables.scss";


.mainDiv {

    border-radius: $border-radius;
    box-shadow: $box-shadow;
    background-color:$colour-blue;
    padding: $padding-single*2;
    height: 30%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media only screen and  (max-width: $mobile-break-point) {
        height: 40%;
        margin: 10px
    }

    div.text{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; 
    height: 100%;
    h3 {
        font-size: $font-size-h3;
        padding: $padding-single;
        margin: 0;
    }

    p {
        font-size: $font-size-p;
        padding: $padding-single;
    }
    }
}