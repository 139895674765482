@import "../../../src/variables.scss";

.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    
    .outer {
        height: 90%;
        width: 95%;
        border-radius: $border-radius;
        background-color: $colour-orange;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;

        .inner {
            height: 90%;
            width: 90%;
            border-radius: $border-radius;
            background-color: $colour-dark-orange;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        
            
            .h2Container {
                height: 10%;
                
                h2 {
                    font-size: $font-size-h3;
                    padding: 10px;
                }
            }
            
            .summary {
                height: 40%;
                text-align: center;
        
                h3 {
                    font-size: $font-size-h4;
                    padding: 10px;
                }
                
                li {
                    font-size: $font-size-progress;
                    padding: 5px;
                    text-align: left;
                    
                }
                
                ul {
                    padding-left: 80px;
                }
            }
            
            .button {
                display: flex;
                flex-direction: colum;
                align-items: center;
                justify-content: space-evenly;

    // a {
    //     display: inline-block;
    //     color: black;
    //     background-color: $colour-blue;
    //     border-radius: $border-radius;
    //     border: none;
    //     padding: 20px;
    //     margin: 20px;
    //     width: 25%;
    //     font-size: $font-size-h4;
        
    //     &:hover {
    //         background-color: $colour-dark-blue;  
    //     }
    // }
                // a {
                //     color: black;
                //     background-color: $colour-blue;
                //     border-radius: $border-radius;
                //     border: none;
                //     font-size: $font-size-p;
                //     width: fit-content;
                //     margin-bottom: 10px;
                    
                //     &:hover {
                //         background-color: $colour-dark-blue;  
                //     }
                // }
            }
        
        }
    }
}

@media only screen and (min-width: $mobile-break-point){
    .container {

        .outer {
            border-radius: $border-radius;
            height: 90%;
            width: 70%;
            padding-bottom: 20px;

            br {
                display: none;
            }
            
            .inner {
                height: 90%;
                width: 90%;
                border-radius: $border-radius;
                margin-bottom: 20px;

                .h2Container {

                    h2 {
                        font-size: $font-size-h4;
                    }
                }

                .summary {
                    margin-bottom: 15px;

                    h3 {
                        font-size: $font-size-h4/1.5;
                    }

                    li {
                        font-size: $font-size-progress/1.5;
                    }
                }

                .buttons {
                    width: 100%;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-bottom: 10px;

                    a{
                        font-size: $font-size-progress/1.5;
                    }
                }
            }
        }
    }

}

@media only screen and (min-width: $tablet-break-point) {

    .container {

        .outer {
            border-radius: $border-radius;
            height: 90%;
            width: 90%;
            padding-bottom: 20px;

            br {
                display: none;
            }
            
            .inner {
                height: 90%;
                width: 90%;
                border-radius: $border-radius;
                margin-bottom: 20px;

                .h2Container {

                    h2 {
                        font-size: $font-size-h2;
                    }
                }

                .summary {


                    h3 {
                        font-size: $font-size-h3;
                    }

                    li {
                        font-size: $font-size-h4;
                    }
                }

                .buttons {
                    width: 100%;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-bottom: 10px;

                    a{
                        font-size: $font-size-h3/1.5;
                    }
                }
            }
        }
    }
    
    .button {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: space-evenly;

    }
    

}
@media only screen and (max-width: 1000px) {
    .button {
        width: 50%;
        flex-direction: column;
    }
}


