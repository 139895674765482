@import "../../../variables.scss";

nav {
    height: $nav-height;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    background-color: $colour-blue;
    padding: 0px;
    margin: 0px;
    
    img {
        height: $nav-height/1.2;
        width: auto;
        padding: 0px;
        margin-left: 10px;
    }

    .FlexContainer {
        display: flex;
        width: 100%;
    
        .NavLinks {
            width: 100%;
            display: flex;
            
            p {
                color: #dfe6e9;
                font-weight: 600;
                border-bottom: 1px solid transparent;
                transition: all 300ms linear 0s;
                cursor: pointer;
                text-transform: uppercase;
                
                
                &:hover {
                    color: #fdcb6e;
                }
            }
            
            .links {
                margin-left: 10px;
                color: $colour-sky;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 75%;
                
                a {
                    text-decoration: none;
                    color: white;
                    padding: 10px;

                    p {
                        border-radius: 0px;  
                    }
                    
                    &:hover {
                        background-color: $colour-dark-blue;
                        border-radius: $border-radius;

                        p span {
                            color: white;
                        }
                    }
                }

                p {
                    padding: 0px;
                    font-size: 20px;
                    text-decoration: none;
                }

                p span {
                    color: $colour-dark-blue;
                    font-size: 20px;
                    padding-right: 5px;
                }
            }


            .account {
                color: $colour-sky;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 35%;
                padding-right: 20px;

                a {
                    text-decoration: none;
                    color: white;
                    padding: 10px;

                    p {
                        border-radius: $border-radius;
                        
                    }

                    &:hover {
                        background-color: $colour-dark-blue;
                        border-radius: $border-radius;

                        p span {
                            color: white;
                        }
                    }
                }
                
                p {
                    padding: 10px;
                    font-size: 20px;
                    border-radius: $border-radius;
                    
                    &:hover {
                        background-color: $colour-dark-blue;
                        border-radius: $border-radius;

                        span{
                            color: white;
                        }
                    }
                }
                
                p span {
                    color: $colour-dark-blue;
                    font-size: 20px;
                    padding-right: 5px;
                }
            }

            @media (max-width: $tablet-break-point) {
                display: none;
            }
        }
    }

    .BurgerWrapper {
        @media (min-width: 769px) {
            margin: auto 0;
            display: none;
        }
    }

}