@import "../../../src/variables.scss";

.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    
    .outer {
        height: 90%;
        width: 95%;
        border-radius: $border-radius;
        background-color: $colour-orange;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        h1 {
            font-size: $font-size-h4;
        }
        
        .inner {
            height: 90%;
            width: 90%;
            border-radius: $border-radius;
            background-color: $colour-dark-orange;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            
        
            .img {
                width: 50%;
                
                img{
                    width: 100%;
                }
            }
        
            .text {
                height: 30%;
                
                p {
                    height: 45%;
                    font-size: $font-size-p;
                    
                }
            }
    
        
            .links {
                height: 30%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                
    
                a {
                    color: black;
                    background-color: $colour-blue;
                    border-radius: $border-radius;
                    border: none;
                    font-size: $font-size-p;
                    width: fit-content;
                    margin-bottom: 10px;
                    
                    &:hover {
                        background-color: $colour-dark-blue;  
                    }
                }
            }
        
        }
    }
    
}

@media only screen and (min-width: $mobile-break-point) {
    
    .container {
        
        .outer {
            border-radius: $border-radius;
            height: 90%;
            width: 70%;
            padding-bottom: 20px;

            br {
                display: none;
            }
            
            .inner {
                height: 90%;
                width: 90%;
                border-radius: $border-radius;
                margin-bottom: 20px;
                
                .img {
                    width: 30%;
                    
                    img{
                        width: 100%;
                    }
                }

                .text {
                    p {
                    font-size: $font-size-progress;
                    }
                    
                }
                
                .links {
                    width: 100%;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-bottom: 10px;
                    
                    a{
                        font-size: $font-size-progress/1.5;
                    }
                }
                
            }
        }
    }
}

@media only screen and (min-width: $tablet-break-point) {
    
    .container {
        
        .outer {
            border-radius: $border-radius;
            height: 90%;
            width: 90%;
            padding-bottom: 20px;

            
            .inner {
                height: 90%;
                width: 90%;
                border-radius: $border-radius;
                margin-bottom: 20px;
                
                .img {
                    width: 20%;
                    
                    img{
                        width: 100%;
                    }
                }

}
}
    }   

}