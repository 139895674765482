@import "../../variables.scss";

.Topics {
    @extend %card;
    // background-color: $colour-orange;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px;

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        padding: 5px;
    }

    .inner {
        @extend %innerCard;
        border-radius: $border-radius;
        margin-bottom: 10px;
        padding: 5px;       
    }

    .link{
        font-size: 50px;

        .playbutton{
            color: #0dba86;

            &:hover {
                color: white;
            }
        }
    }
    .heading {
        font-size: $font-size-card-title;
    }
}


@media only screen and (min-width: $tablet-break-point) {

    .Topics {
        display: flex;
        flex-direction: column;
        height: 500px;
        width: 380px;
    
        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
        }
    
        // .inner {
            
        // }
    
     
        .heading {
            font-size: $font-size-card-title;
        }
    }
}