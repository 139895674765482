@import '../../variables.scss';

.onBeanSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    
    .currentBean {
        display:flex;
        flex-direction: row;
        width: 90%;
        height: 60%;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        .currentBeanImage {
            width: 50%;
            height: 100%;
            display:flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 768px) {
                width: 90%;
            }

            img {
                width: 60%;
                height: auto;
                padding: 0;
                margin: 0;
            }
        }

        .currentBeanList {
            display:flex;
            flex-direction: column;
            max-height: 100%;
            justify-content: space-evenly;
            align-items: center;
            width: 50%;
            font-size: 0.8em;

            @media only screen and (max-width: 768px) {
                width: 90%;
                padding-bottom: 20px;
            }

            h3 {
                font-size: 1.5em;
            }

            p {
                font-size: 1.7em;
                padding: 10px;
            }

            .feelingListSection{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px;

            }
        }
    }

    .otherBeans {
        display:flex;
        flex-direction: row;
        height: 30%;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        font-size: 1em;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        .otherBeanContainer{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            border-radius: $border-radius;
            padding-bottom: 5px;
            height: 90%;

    
            &:hover{
                background-color: $colour-orange;
            }

            .beanImageContainer{
                height: 70%;
                width: 80%;

                img {
                    width: 40%;
                    padding: 0;
                    margin: 0;
                }
            }
    
            span {
                height: 20%;
                font-size: 1em;
            }
        }
    }

}

.noBeanContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.allbeans {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.beanDiv {
    height: 20vh;
    width: 20vw;

    @media only screen and (max-width: 1024px) {
        
    }

    @media only screen and (max-width: 768px) {
        
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.beanRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .beanContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius;
        padding-bottom: 5px;

        &:hover{
            background-color: $colour-orange;
        }
        
        span {    
            font-size: 1em;
        }
    }
}
