@import '../../variables.scss';

.Flexed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - #{$nav-height});

    @media only screen and (max-width: 768px) {
        height: unset;
        padding-top: 10px;
    }
} 

.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.outer {
    height: 90%;
    width: 95%;
    background-color: $colour-orange;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        padding: 2%;
        font-size: 1.5em;

    }
}

.inner {
    height: 90%;
    width: 90%;
    border-radius: $border-radius;
    background-color: $colour-dark-orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        padding-top: 30px;

    }

    p {
        padding: 30px;
        font-size: $font-size-h3;
    }
       
    }



