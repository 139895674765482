@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap');

* {
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat Alternates', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/background.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;

}

h1, h2, h3, h4, h5, p{
  color: #202020
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}

@media only screen and (max-width: 426px) {
  .rec-arrow {
    display: none;
  }
}