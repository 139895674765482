@import "../../../src/variables.scss";
.container {
    height: calc(100vh - #{$nav-height});
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $padding-single;
    @media only screen and (max-width: $mobile-break-point) {
        height: 100%;
    }
    h2 {
        padding: 5px;
        font-size: $font-size-h3*0.7;
        margin-bottom: 10px;
        position: absolute;
        top: 10px;
    }
}

.outer {
    height: 90%;
    width: fit-content;
    background-color: $colour-orange;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: $mobile-break-point) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.inner {
    height: 90%;
    width: 90%;
    border-radius: $border-radius;
    background-color: $colour-dark-orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px;
    .inputContainer {
        display: flex;
        flex-direction: column;
        float: left;
        p {
            font-size: $font-size-p;
            padding: 0px;
            text-align: justify;
        }
    }
    form {
        font-size: $font-size-p * 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            margin-top: 10px;
        }
        input.btn,
        p.btn {
            margin-top: 10px;
            color: black;
            background-color: $colour-blue;
            border-radius: $border-radius;
            width: 150px;
            border: none;
            padding: 5px;
            margin: 15px;
            font-size: 100%;
            text-align: center;
            cursor: pointer;
            box-shadow: $box-shadow;
            &:hover {
                background-color: $colour-dark-blue;
            }
            &.active {
                background-color: green;
            }
            *:focus {
                outline: none;
            }
            &:disabled {
                background-color: grey;
                opacity: 0.5;
            }
            *:focus {
                outline: none;
            }
            @media only screen and (max-width: $mobile-break-point) {
                width: 100px;
                padding: 15px;
                margin: 10px;
                font-size: 90%;
                border-radius: 20px;
            }
        }
        .relationship {
            background-color: #72C9CF;
            border-radius: 20px;
            padding: 3px;
        }
        .work {
            background-color: #B4C740;
            border-radius: 20px;
            padding: 3px;
        }
        .life {
            background-color: #f5b700;
            border-radius: 20px;
            padding: 3px;
        }
        .diet {
            background-color: #fb6376;
            border-radius: 20px;
            padding: 3px;
        }
        .bodyImage {
            background-color: #a7abdd;
            border-radius: 20px;
            padding: 3px;
        }
    }
}

.graph {
    margin-top: $margin-single * 16;
    min-width: 130px;
    min-height: 130px;
    margin-bottom: 5px;
}

@media screen and (min-width: $mobile-break-point) {
    .container h2 {
        font-size: $font-size-h3*0.9;
    }
}

@media screen and (min-width: 768px) {
    .inner {
        flex-direction: row;
        .inputContainer {
            width: 50%;
            p {
                font-size: $font-size-p * 1.0;
                margin-bottom: 8px;
            }
            form {
                font-size: $font-size-p * 1.0;
                .btnflex {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .container h2 {
            font-size: $font-size-h3*2;
        }
    }
}