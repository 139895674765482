@import "../../variables.scss";
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
h1 {
    font-size: 60px;
    padding: 10px;
}
p {
    text-align: center;
    padding: 5px 10px;
    text-align: center;
    font-size: 20px;
}

.header {
    border: 2px solid $colour-orange;
    background-color: $colour-orange;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    height: 180px;
    margin: 50px;
    padding: 0px 10px;
    opacity: 0.9;

    h1 {
        font-weight: bold;
        font-size: 40px;
    }

    p {
        font-size: 1em;
    }
}

.showInstructionsStyle {
    font-size: $font-size-h4;
}

.toolPage {
    border: 2px solid $colour-dark-purple;
    background-color: $colour-purple;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 10px;
    height: 90%;
    width: 60%;
    margin: 0 auto;
    opacity: 0.9;
    
    
    
    
    input {
        height: 80px;
        width: 90%;
        border: 2px solid $colour-dark-purple;
        box-shadow: $box-shadow;
        margin: 10px;
        font-size: 24px;
        
    }

    .buttons {
        display: flex;
        justify-content: space-evenly;
        width: 50%;
        margin: 0 auto;


       
        button {
            background-color: $colour-blue;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            padding: 15px;
            border: none;
            width: 120px;
            margin: 0, auto;
            margin-top: 6px;
            display: inline-block;
        }
    }
    button:hover {
        cursor: pointer;
        background-color: $colour-dark-blue;
    }
    
}
.affirmationCard {
    border: 2px solid $colour-dark-orange;
    background-color: $colour-orange;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    font-weight: bold;
    padding: 10px;
    height: 90%;
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
    opacity: 0.9;
    }
@media only screen and (max-width: 812px) {
    .header {
        height: fit-content;
        h1 {
            font-size: $font-size-h4;
        }
        p {
            font-size: $font-size-p;
        }
    }
    .toolPage {
        padding-bottom: 10px;
        .buttons {
            // 
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0, auto;
        }
    }
}
@media only screen and (max-width: $tablet-break-point) {
    .buttons {
        margin: 20px;
    }
}
@media only screen and (max-width: 1024px) {
    .buttons {
        display: flex;
        justify-content: space-around;
    }
}