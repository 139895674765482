@import "../../../variables.scss";

.collapseMenu {
    position: fixed;
    top: 4.5rem;
    left: 0;
    right: 0;
    // background-color: #89cfeb;
    background-image: linear-gradient(#89cfeb, $colour-dark-blue);
    z-index: 3;
    height: 100%;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .navLinks {
        list-style-type: none;
        padding: 2rem 2rem 2rem 2rem;
        height: 70%;

        p {
            font-size: 1.8rem;
            line-height: 2.5;
            color: #dfe6e9;
            text-transform: uppercase;
            cursor: pointer;
            text-decoration: none;
            margin-bottom: 10px;
            text-shadow: 0px 0px 8px $colour-dark-blue;
            font-weight: 1000;


            a {
                color: #dfe6e9;
                text-decoration: none;
            }

            &:hover {
                background-color: $colour-dark-blue;
                border-radius: $border-radius;
                color: #fdcb6e;

                a {
                    color: #fdcb6e;
                }
            }
        }
    }
}