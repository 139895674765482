@import '../../variables.scss';

.Flexed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - #{$nav-height});

    @media only screen and (max-width: 768px) {
        height: unset;
        padding-top: 10px;
    }
} 