@import "../../../src/variables.scss";

.container {
    // height: 70%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    border-radius: 30px;

    @media screen and (max-width: 900px) {
        // height: 90%;
    }
}

.outer {
    // height: 500px;
    // width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        padding: 20px;
        font-size: $font-size-h2;

        @media screen and (max-width: 650px) {
            font-size: 30px;
        }
    }
}

.inner {
    height: fit-content;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;

    img {
        height: 200px;
        padding-top: 30px;
    }

    p {
        padding: 30px;
        font-size: $font-size-h3;

        @media screen and (max-width: 650px) {
            font-size: 20px;
        }
    }

    .links {
        color: black;
        background-color: $colour-blue;
        border-radius: $border-radius;
        width: 250px;
        border: none;
        padding: 20px;
        margin: 20px;
        font-size: $font-size-h4;
        cursor: pointer;
        box-shadow: $box-shadow;
        display: inline-block;
        @media screen and (max-width: 380px) {
            width: 180px;
        }
        
        &:hover {
            background-color: $colour-dark-blue;  
        }
    
        *:focus {
            outline: none;
        }
    }
    
    *:focus {
        outline: none;
    }

    @media screen and (max-width: 650px) {
        font-size: 20px;
    }

}

