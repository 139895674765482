@import "../../../src/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap');


.button {
    color: black;
    background-color: $colour-blue;
    border-radius: $border-radius;
    width: 250px;
    border: none;
    padding: 20px;
    margin: 20px;
    font-size: 90%;
    cursor: pointer;
    box-shadow: $box-shadow;
    font-family: 'Montserrat Alternates', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    &:hover {
        background-color: $colour-dark-blue;
    }

    &.active {
        background-color: green;
    }

    *:focus {
        outline: none;
    }

    &:disabled {
        background-color: grey;
        opacity: 0.5;
    }

*:focus {
    outline: none;
}
@media only screen and (max-width: 400px) {
    width: 180px;
}
}
